.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 48px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;

    >div {
      margin: 8px
    }
  }

  .footer__help-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    min-width: 169px;
    min-height: 41px;

    background: $color-gray-07;
    border-radius: 40px;
    padding: 12px;

    @extend .p-l-bold;
    color: $color-gray-06;

    img {
      margin-right: 8px;
    }
  }

  .footer__legal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    div {
      @extend .p-l-regular;
      color: $color-gray-06;
      margin: 0 12px;
      cursor: pointer;

      &:hover {
        color: $color-gray-04;
      }
    }
  }

  .footer__credits {
    @extend .p-l-regular;
    color: $color-gray-04;
    text-align: right;
  }
}
