.landing-view {
  overflow-x: clip;

  .landing-view__header {
    display: none;
    background: $color-gray-01;
    height: 65px;
    width: 100%;
    padding: 0 48px;
    position: sticky;
    top: 0;

    &.sticky {
      display: flex;
      z-index: 109;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: 16px;
      }

      @media (max-width: 560px) {
        height: 120px;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 12px;

      }
    }
  }

  .landing-view__title-logo {
    margin-bottom: 48px;
    max-width: 85%;

    img {
      width: 100%;
    }
  }

  .landing-view__title {
    max-width: 586px;
    width: 90%;

    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    margin-bottom: 24px;
  }

  .landing-view__subtitle {
    max-width: 586px;
    width: 90%;
    font-weight: 400;
    font-size: 24px;
  }

  .landing-view__cta {
    max-width: 586px;
    width: 90%;
    font-weight: 600;
    font-size: 28px;
    line-height: 43px;
    margin-top: 24px;
  }

  .landing-view__text {
    max-width: 586px;
    width: 90%;
    font-weight: 400;
    font-size: 19px;
    line-height: 29px;
  }

  .landing-view__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }

  .landing-view__section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    &:nth-child(2) {
      background: $color-blue;
      height: 100vh;
      max-height: 800px;
      min-height: 675px;

      .landing-view__btn {
        margin-top: 48px;
      }

      .landing-view__chica-img {
        position: absolute;
        left: 0;
        top: 80px;
        transition: all 0.5s ease-in-out;

        @media (max-width: 900px) {
          left: -200px;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    &:nth-child(3) {
      height: 770px;
      background: $color-pink;

      .landing-view__paloma-img {
        margin-top: -290px;
      }

      .landing-view__apps {
        margin-top: 63px;
        max-width: 95%;
        display: flex;

        img {
          width: 100%;
        }

        >div {
          position: relative;

          .landing-view__apps-tag {
            background: #121212;
            padding: 2px 12px;
            font-family: Titillium Web;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 2px;
            color: #FFFFFF;
            position: absolute;
            top: -12px;
            transform: translateX(50%);
            right: 50%;
          }
        }
      }

    }

    &:nth-child(4) {
      height: 600px;
      background: #ffcc8b;
      transition: all 0.5s ease-in-out;

      @media (max-width: 900px) {
        height: auto;
        padding: 80px 40px;
        transition: all 0.5s ease-in-out;
      }

      .landing-view__section-content {
        position: relative;
        left: -100px;
        transition: all 0.5s ease-in-out;

        @media (max-width: 1400px) {
          left: -50px;
          transition: all 0.5s ease-in-out;
        }

        @media (max-width: 900px) {
          left: 20px;
          transition: all 0.5s ease-in-out;
        }

        div {
          text-align: left;
        }
      }

      .landing-view__palomitas-img {
        position: absolute;
        left: 70px;
        top: 180px;
        transition: all 0.5s ease-in-out;

        @media (max-width: 1400px) {
          left: -50px;
          transition: all 0.5s ease-in-out;
        }

        @media (max-width: 1100px) {
          left: -100px;
          transition: all 0.5s ease-in-out;
        }

        @media (max-width: 900px) {
          left: -200px;
          transition: all 0.5s ease-in-out;
        }
      }

      .landing-view__mac-img {
        position: absolute;
        right: 0;
        top: 90px;
        transition: all 0.5s ease-in-out;

        @media (max-width: 1400px) {
          right: -100px;
          transition: all 0.5s ease-in-out;
        }

        @media (max-width: 1100px) {
          right: -200px;
          transition: all 0.5s ease-in-out;
        }

        @media (max-width: 900px) {
          right: -600px;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    &:nth-child(5) {
      height: 620px;
      background: $color-green;
      transition: all 0.5s ease-in-out;

      @media (max-width: 900px) {
        height: auto;
        padding: 80px 40px;
        transition: all 0.5s ease-in-out;
      }

      .landing-view__section-content {
        position: relative;
        left: 100px;

        transition: all 0.5s ease-in-out;

        @media (max-width: 1400px) {
          left: 50px;
          transition: all 0.5s ease-in-out;
        }

        @media (max-width: 900px) {
          left: 20px;
          transition: all 0.5s ease-in-out;
        }

        div {
          text-align: left;
        }
      }

      .landing-view__phone-img {
        position: absolute;
        left: 0;
        top: 65px;
        transition: all 0.5s ease-in-out;

        @media (max-width: 1400px) {
          left: -145px;
          transition: all 0.5s ease-in-out;
        }

        @media (max-width: 1100px) {
          left: -255px;
          transition: all 0.5s ease-in-out;
        }

        @media (max-width: 900px) {
          left: -500px;
          transition: all 0.5s ease-in-out;
        }
      }

      .landing-view__bici-img {
        position: absolute;
        right: 0;
        top: 350px;
        z-index: 3;
        transition: all 0.5s ease-in-out;

        @media (max-width: 900px) {
          right: -300px;
          display: none;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    &:nth-child(6) {
      background: #ffffff;

      .landing-view__cta {
        margin-top: 104px;
      }

      .landing-view__soon {
        margin-top: 98px;
        margin-bottom: 40px;

        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 2px;
        color: #4f4f4f;
      }

      .landing-view__soon-cards {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  .landing-view__footer {
    margin-top: 190px;
    margin-bottom: 24px;
  }
}