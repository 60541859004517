.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: transparent;
  border: none;
  background-color: $color-soft-black;
  border-radius: 40px;
  padding: 8px 24px;
  min-height: 41px;

  @extend .p-xl-bold;
  color: #ffffff;

  &:hover {
    opacity: 0.9;
  }

  &.button--disabled {
    background-color: #e0e0e0 !important;
    pointer-events: none;
  }

  &.button--size-big {
    height: 49px;
    padding: 12px 24px;
  }

  &.button--color-white {
    background-color: #ffffff;
    color: #000000;
  }

  &.button--color-lightgray {
    background-color: $color-gray-07;
  }

  &.button--color-darkgray {
    background-color: $color-gray-01;
  }

  &.button--color-green {
    background-color: $color-green;
    color: $color-gray-01;
  }

  &.button--color-blue {
    background-color: $color-blue;
    color: $color-gray-01;
    height: 55px;
  }

  &.button--color-transparent-black {
    background-color: transparent;
    color: $color-gray-01;
    color: #000000;
  }

  &.button--color-transparent-white {
    background-color: transparent;
    color: $color-gray-01;
    color: #ffffff;
  }
}
