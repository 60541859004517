.home-header {
  height: 89px;
  width: 100%;

  .home-header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    color: #ffffff;
    padding: 0 60px;
    background-color: $color-gray-01;

    .home-header__logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      img {
        display: block;
      }
    }

    .home-header__profile-menu {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        .home-header__profile-menu-content {
          display: block;
        }
      }

      .home-header__profile-menu-button {
        @extend .p-xl-semi;
        color: #ffffff;
        text-transform: uppercase;
        cursor: pointer;
        text-align: right;

        span {
          margin-right: 6px;
        }
      }

      .home-header__profile-menu-content {
        background: #ffffff;
        border: 1px solid $color-gray-05;
        padding: 0 24px;
        min-width: 130px;
        position: absolute;
        right: -6px;
        top: 64px;
        display: none;
        z-index: 10;

        .home-header__profile-menu-item {
          @extend .p-l-semi;
          color: $color-gray-06;
          padding: 12px 0;
          cursor: pointer;
          border-bottom: 1px solid $color-gray-07;
          text-align: left;

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.home-header__separator {
  border-top: 1px solid $color-gray-03;
  width: 100%;
  display: flex;

  .line {
    height: 8px;
    width: 100%;

    &.line--orange {
      background-color: $color-light-orange;
    }

    &.line--blue {
      background-color: $color-blue;
    }

    &.line--pink {
      background-color: $color-pink;
    }

    &.line--green {
      background-color: $color-green;
    }
  }
}

// Responsive
@media (max-width: 768px) {
  .home-header {
    height: 189px;
    width: 100%;

    .home-header__content {
      flex-wrap: wrap;

      height: 180px;

      .button {
        order: 2;
      }

      .home-header__logo {
        width: 100%;
        order: 1;
        margin-top: 32px;

        position: relative;
        left: auto;
        transform: none;

        img {
          margin: 0 auto;
        }
      }

      .home-header__profile-menu {
        order: 3;
        height: auto;
      }
    }
  }
}
