// Font imports

// @font-face {
//   font-family: 'Asap';
//   src: url('../assets/fonts/asap/Asap-Regular.ttf');
// }

// @font-face {
//   font-family: 'Maax';
//   src: url('../assets/fonts/maax/Maax-Medium.eot');
//   src: local('Maax Medium'), local('Maax-Medium'),
//     url('../assets/fonts/maax/Maax-Medium.eot?#iefix') format('embedded-opentype'),
//     url('../assets/fonts/maax/Maax-Medium.woff') format('woff'),
//     url('../assets/fonts/maax/Maax-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

@font-face {
  font-family: 'Titillium Web';
  src: url('../assets/fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('../assets/fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('../assets/fonts/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: 700;
}
