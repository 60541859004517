.alert-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 4px;
  margin-bottom: 10px;

  position: relative;
  width: 350px;

  border-radius: 8px;

  transition: all 0.3s;

  animation-duration: 0.3s;
  animation-name: animate-fade;
  animation-delay: 0.3s;
  animation-fill-mode: backwards;

  @keyframes animate-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  span {
    @extend .p-xl-semi;
    color: $color-white-text;
  }
}

.alert-message--red {
  background: $color-alert-red;
}

.alert-message--green {
  background: $color-alert-green;
}
