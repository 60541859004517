/* Hay un @media abajo */
$column-breakpoint: 1180px;

.profile-view {
  .profile-view__wrapper {
    max-width: 1097px;
    width: 100%;

    z-index: 0;
    text-align: left;

    .profile-view__title {
      @extend .headings-h2;
      text-align: center;
      padding: 40px;
    }

    .profile-view__content-row {
      display: flex;
      justify-content: space-between;

      > div {
        width: 442px;
      }

      > div:first-child {
        width: 630px;
      }

      @media (max-width: $column-breakpoint) {
        flex-direction: column;

        > div,
        > div:first-child {
          width: 100%;
        }
      }
    }

    .profile-view__form-card {
      background: #ffffff;
      border: 1px solid $color-gray-07;
      border-radius: 4px;
      padding: 24px;
      margin-bottom: 16px;
    }

    .profile-view__form-label {
      @extend .p-xl-semi;
      color: $color-gray-01;
      margin-bottom: 12px;
    }

    .input-text {
      margin-bottom: 12px;
      border-radius: 4px;

      .input-text__label {
        color: $color-gray-08;
      }
    }

    .profile-view__column-wrapper {
      display: flex;
      justify-content: space-between;

      > button:first-child,
      > div:first-child {
        width: 100%;
        margin-right: 8px;
      }

      > button:last-child,
      > div:last-child {
        width: 100%;
        margin-left: 8px;
      }
    }

    .profile-view__licenses-table-head {
      padding-bottom: 0.5em;
    }

    .profile-view__licenses-table-row {
      display: flex;
      align-items: center;
      padding: 0.5em 0;

      @extend .p-xl-regular;
      color: $color-gray-01;

      > div {
        flex: 1;
        padding-left: 0.5em;
        line-height: 1.5em;
      }

      .profile-view__app-column {
        .profile-view__app-content {
          display: flex;
          align-items: center;

          img {
            padding-right: 0.5em;
          }
        }
      }

      .profile-view__licenses-isbn {
        max-height: 3em;
        overflow-y: auto;
        align-items: flex-start;

        scrollbar-width: thin;
        scrollbar-color: $color-gray-05 $color-gray-07;
      }

      .profile-view__licenses-isbn::-webkit-scrollbar {
        width: 5px;
      }

      .profile-view__licenses-isbn::-webkit-scrollbar-track {
        background: $color-gray-07;
      }

      .profile-view__licenses-isbn::-webkit-scrollbar-thumb {
        background-color: $color-gray-05;
        border-radius: 12px;
        border: 1px solid $color-gray-07;
      }

      .profile-view__expiration-date-column {
        flex: 0.75;
      }
    }

    // Select de colegio

    .school-async-select {
      margin-bottom: 12px;
      text-align: left;

      .select__control {
        @extend .p-xl-regular;
        min-height: 50px;
        border-radius: 4px;
        box-shadow: none;
        border: 1px solid $color-gray-08;
        box-sizing: border-box;

        .select__value-container {
          padding: 2px 16px;
        }

        .select__placeholder {
          color: $color-gray-08;
        }

        .select__indicator-separator {
          display: none;
        }
      }

      .select__menu-list {
        max-height: 200px;
        z-index: 109;
      }

      .select__option {
        font-size: 14px;
        line-height: 23px;
        color: $color-gray-06;
      }

      .select__option--is-focused {
        background: $color-gray-07;
        font-weight: 600;
      }

      .option-text {
        width: 100%;
        .option-left-text {
          width: 100%;
          text-align: left;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 600;

          // &:active,
          // &:hover {
          //   direction: rtl;
          //   padding-right: 1em;
          //   &:after {
          //     content: '\200E‎';
          //   }
          // }
        }
        .option-right-text {
          width: 100%;
          text-align: left;
          white-space: nowrap;

        }
      }
    }

    // Multiselect de curso

    .grouped-multi-select {
      margin-bottom: 12px;
      text-align: left;

      .select__control {
        @extend .p-xl-regular;
        min-height: 50px;
        border-radius: 4px;
        box-shadow: none;
        border: 1px solid $color-gray-08;
        box-sizing: border-box;

        .select__value-container {
          padding: 2px 16px;
        }

        .select__placeholder {
          color: $color-gray-08;
        }
      }

      .group-heading-wrapper {
        display: flex;

        .group-check {
          width: 16px;
          height: 16px;
          background: $color-gray-01;
          border: 1px solid $color-gray-01;
          border-radius: 2px;
        }
      }

      .select__option {
        font-size: 14px;
        line-height: 23px;
        color: $color-gray-06;
      }

      .select__option--is-focused {
        background: $color-gray-07;
        font-weight: 600;
      }

      .select__option--is-selected {
        background: $color-gray-09;
      }
    }

    // Multiselect de asignaturas

    .basic-multi-select {
      margin-bottom: 12px;
      text-align: left;

      .select__control {
        @extend .p-xl-regular;
        min-height: 50px;
        border-radius: 4px;
        box-shadow: none;
        border: 1px solid $color-gray-08;
        box-sizing: border-box;

        .select__value-container {
          padding: 2px 16px;
        }

        .select__placeholder {
          color: $color-gray-08;
        }
      }

      .select__option {
        font-size: 14px;
        line-height: 23px;
        color: $color-gray-06;
      }

      .select__option--is-focused {
        background: $color-gray-07;
        font-weight: 600;
      }

      .select__option--is-selected {
        background: $color-gray-09;
      }
    }
  }
}

@media (max-width: 560px) {
  .profile-view {
    .profile-view__wrapper {
      .profile-view__licenses-table-head {
        padding-bottom: 0.5em;
      }

      .profile-view__licenses-table-head {
        display: none;
      }

      .profile-view__licenses-table-row {
        flex-flow: column;
        border-bottom: 1px solid $color-gray-08;

        > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: right;
        }

        .profile-view__app-column {
          .profile-view__app-content {
            display: inline-block;
            img {
              vertical-align: top;
            }
          }
        }

        > div::before {
          content: attr(data-label);
          color: $color-gray-01;
          font-weight: 600;
        }

        .profile-view__isbn-column {
          align-items: flex-start;
        }

        .profile-view__licenses-isbn {
          padding-right: 0.5em;
        }
      }

      .profile-view__licenses-table-row:last-of-type {
        border-bottom: none;
      }
    }
  }
}
