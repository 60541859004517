.change-password-view {
  background-color: $color-pink;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .change-password-view__logo {
    position: absolute;
    top: 45px;
    left: 48px;
  }

  .change-password-view__close-btn {
    z-index: 0;
    position: absolute;
    top: 45px;
    right: 48px;
  }

  .change-password-view__content {
    // width: 90%;
    // max-width: 537px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // flex-grow: 1;

    width: 90%;
    max-width: 537px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 48px;

    .change-password-view__title {
      @extend .headings-h1;
      margin-bottom: 38px;
      text-align: center;
    }

    .change-password-view__subtitle {
      @extend .p-xl-regular;
      margin-bottom: 38px;
    }

    .change-password-view__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .change-password-view__password-input {
        width: 100%;
        max-width: 350px;
        margin-bottom: 38px;
      }
    }

    .change-password-view__check {
      width: 100%;
      margin-bottom: 24px;
    }

    .change-password-view__actions {
      margin-top: 16px;
      display: flex;
      width: 100%;
      max-width: 473px;
      justify-content: center;
      align-items: center;

      .button {
        margin: 0 16px;
      }
    }
  }

  .change-password-view__button-change-password {
    @extend .reset-button-style;

    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-bottom: 38px;
  }

  .change-password-view__button-change-password-enabled {
    background: $color-gray-01;
    cursor: pointer;
    z-index: 2;
  }

  .change-password-view__button-change-password-disabled {
    background: rgba($color-gray-01, 0.25);
  }
}
