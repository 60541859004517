.sign-up-form-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 404px;

  .sign-up-form-password__title {
    @extend .headings-h1;
    margin-bottom: 12px;
  }
  .sign-up-form-password__subtitle {
    @extend .p-xl-regular;
    margin-bottom: 38px;
  }

  .sign-up-form-password__form {
    width: 350px;

    .sign-up-form-password__password-input {
      margin-bottom: 16px;
    }
  }

  .sign-up-form-password__checkbox-wrapper {
    margin-top: 36px;
  }

  .sign-up-form-password__checkbox {
    width: 100%;
    text-align: left;
    margin-bottom: 12px;
    display: flex;
    

    & > span:nth-child(1) > div > label > span {
      top: -1em;
    }
  }
}
