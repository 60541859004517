// Color variables

$color-gray-01: #121212;
$color-gray-02: #1d1d1b;
$color-gray-06: #4f4f4f;
$color-gray-10: #7b7b7b;
$color-gray-04: #828282;
$color-gray-08: #bdbdbd;
$color-gray-09: #d3d3d3;
$color-gray-05: #e0e0e0;
$color-gray-03: #e4e4e4;
$color-gray-07: #f2f2f2;

$color-soft-black: #1d1d1b;
$color-orange: #ed7011;
$color-light-orange: #ffd296;
$color-pink: #f5afc3;
$color-blue: #8cd2eb;
$color-green: #d4e796;
$color-yellow: #fff064;
$color-red: #dc5231;
$color-brown: #221520;
$color-red-byme: #ed0e0e;

$color-alert-red: #f15e5e;
$color-alert-green: #63ae39;

$gradient-orange: linear-gradient(137.52deg, #ff8200 1.73%, #ff4100 96.59%);
$gradient-red: linear-gradient(148.48deg, #ff531d 18.3%, #b4131c 98.15%);

$color-black-logo: $color-gray-02;
$color-black-text: $color-gray-01;
$color-white-text: #ffffff;

$color-btn-gray: #757575;

// Shadows
$social-btn-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
