.packages-item {
  min-height: 53px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid $color-gray-07;
  padding: 10px;

  &:last-child {
    border: none;
  }

  .packages-item__name {
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    margin-top: 6px;

    .checkbox {
      span {
        @extend .p-xl-semi;
        color: $color-gray-04;
      }

      input:checked ~ .checkbox__checkmark {
        background-color: $color-gray-06;
        border: 1px solid $color-gray-06;
      }
    }
  }

  .packages-item__options {
    display: flex;
    flex-wrap: wrap;
    flex: none;
    flex-grow: 1;
    /*justify-content: space-between;*/
  }

  .packages-item__option-tag {
    border: 1px solid $color-gray-07;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px;
    margin: 4px;

    @extend .p-m-regular;
    color: $color-gray-06;
  }

  .packages-item__option-tag--show-more {
    cursor: pointer;
    @extend .p-m-semi;
    color: $color-orange;
  }
}
