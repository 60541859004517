// Typography styles

// Use with @extend .title-h1

// .title-h1 {
// }

$font-primary: 'Titillium Web';

/* Titles */
.headings-h1 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
}

/* Headings/h2 */
.headings-h2 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
}

/* Headings/h4 */
.headings-h4 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 35px;
}

/* Paragraph XL/XL Regular */
.p-xl-regular {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
}

/* Paragraph XL/XL Semi */
.p-xl-semi {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
}

/* Paragraph XL/XL Bold */
.p-xl-bold {
  font-family: $font-primary;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
}

/* Paragraph L/L Regular */
.p-l-regular {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}

/* Paragraph L/L Semi */
.p-l-semi {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
}

/* Paragraph L/L Bold */
.p-l-bold {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
}

/* Paragraph M/M Regular */
.p-m-regular {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

/* Paragraph M/M Semi */
.p-m-semi {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
}

/* Social Button */
.label-social-btn {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
}
