.section-layout {
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #F9F9F9;

  .section-layout__header {
    width: 100%;
  }

  .section-layout__content {
    width: 100%;
    flex-grow: 1;
    padding: 0 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section-layout__footer {
    width: 100%;
  }
}
