.contact-tabs {
  margin: 24px 0;

  .contact-tabs__tabs {
    height: 41px;
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .contact-tabs-tab__button {
      @extend .p-xl-bold;
      color: #BDBDBD;
      background: #FFFFFF;
      border: 1px solid #000000;
      box-sizing: border-box;
      padding: 8px 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 20px;
      text-align: center;

      &:first-child {
        border-radius: 32px 0px 0px 32px;
      }

      &:last-child {
        border-radius: 0px 32px 32px 0px;
      }

      &.contact-tabs-tab__button--active {
        color: #FFFFFF;
        background: #121212;
      }
    }
  }

  .contact-tabs__tab-content {
    max-width: 493px;
    width: 100%;
    margin: 0 auto;
  }
}
