//TODO: variables
//TODO: fix input height

.input-text {
  position: relative;
  width: 100%;
  height: 50px;

  background: #ffffff;
  /* Gray 04 */

  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 16px;

  &.input-text-disabled {
    background: $color-gray-03 !important;
  }

  .input-text__input {
    opacity: 0;

    width: 100%;
    height: 50px;

    background: transparent;
    border: none;
    transition: all ease-in 0.1s;

    /* Paragraph XL/XL Regular */

    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #4f4f4f;

    &:disabled {
      color: $color-gray-10;
    }

    &.input-text__input--active {
      outline: none;
      height: 34px;
      margin-top: 14px;
      opacity: 1;
      transition: all ease-in 0.1s;

      ~ .input-text__label {
        height: 23px;
        top: 0;
        left: 18px;
        transition: all ease-in 0.1s;
        /* Paragraph M/M Regular */

        font-family: Titillium Web;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
      }
    }
  }

  .input-text__label {
    position: absolute;
    height: 50px;
    top: 0;
    left: 16px;
    transition: all ease-in 0.1s;

    /* Paragraph XL/XL Regular */

    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    /* or 167% */

    display: flex;
    align-items: center;

    color: #1d1d1b;
  }
}

.input-text__input:focus,
.input-text__input:active {
  outline: none;
  height: 34px;
  margin-top: 14px;
  opacity: 1;
  transition: all ease-in 0.1s;

  ~ .input-text__label {
    height: 23px;
    top: 0;
    left: 18px;
    transition: all ease-in 0.1s;
    /* Paragraph M/M Regular */

    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
  }
}
