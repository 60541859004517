.landing-section-header {
  height: 74px;
  width: 100%;

  .landing-section-header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    color: #ffffff;
    padding: 0 60px;
    background-color: $color-gray-01;

    .landing-section-header__back-button {
      cursor: pointer;
      color: #F5AFC3
    }

    .landing-section-header__button {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.landing-section-header__separator {
  border-top: 1px solid $color-gray-03;
  width: 100%;
  display: flex;

  .line {
    height: 8px;
    width: 100%;

    &.line--orange {
      background-color: $color-light-orange;
    }

    &.line--blue {
      background-color: $color-blue;
    }

    &.line--pink {
      background-color: $color-pink;
    }

    &.line--green {
      background-color: $color-green;
    }
  }
}
