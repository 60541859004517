$transition-speed: 0.8s;
$transition-anim: ease-in-out;
$layer1-color: $color-pink;
$layer2-color: $color-blue;
$layer3-color: $color-green;

.animated-background-layout {
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  .animated-background-layout__content {
    position: relative;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: 840px;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 50px 30px;
  }

  .animated-background-layout__background {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    height: 100%;
    width: 100%;

    .animated-background-layout__background--layer1 {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 100vh;
      height: 100%;
      background-color: $layer1-color;
      z-index: 3;
      transition: width $transition-anim $transition-speed;
    }

    .animated-background-layout__background--layer2 {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 100vh;
      height: 100%;
      background-color: $layer2-color;
      z-index: 2;
      transition: width $transition-anim $transition-speed;
    }

    .animated-background-layout__background--layer3 {
      position: absolute;
      top: 0;
      left: 0;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-color: $layer3-color;
      z-index: 1;
      transition: width $transition-anim $transition-speed;
    }
  }

  .animated-background-layout__background--step-1 {
    .animated-background-layout__background--layer1 {
      width: 90%;
    }

    .animated-background-layout__background--layer2 {
      width: 100%;
    }
  }

  .animated-background-layout__background--step-2 {
    .animated-background-layout__background--layer1 {
      width: 0;
    }

    .animated-background-layout__background--layer2 {
      width: 90%;
    }
  }

  .animated-background-layout__background--step-3 {
    .animated-background-layout__background--layer1 {
      width: 0;
    }

    .animated-background-layout__background--layer2 {
      width: 0;
    }
  }
}
