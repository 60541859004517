.fade-in-section {
  opacity: 0;
  transform: translateY(70px);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section--left {
  transform: translateX(-70px);
}

.fade-in-section--right {
  transform: translateX(70px);
}

.fade-in-section--top {
  transform: translateY(-70px);
}

.fade-in-section--bottom {
  transform: translateY(70px);
}
