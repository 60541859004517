.add-license-popup {
  background-color: #F4AFC2;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .add-license-popup__logo {
    position: absolute;
    top: 45px;
    left: 48px;
  }

  .add-license-popup__close-btn {
    position: absolute;
    top: 45px;
    right: 48px;
  }

  .add-license-popup__content {
    width: 90%;
    max-width: 723px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 48px;

    .add-license-popup__check {
      width: 100%;
      margin-bottom: 24px;
    }

    .add-license-popup__title {
      @extend .headings-h1;
      margin-bottom: 24px;
    }

    .info-popup__subtitle {
      @extend .p-xl-regular;
      margin-bottom: 16px;

      a {
        font-weight: 700;
        color: $color-gray-01;
        text-decoration: none;
      }
    }

    .info-popup__actions {
      margin-top: 16px;
      display: flex;
      width: 100%;
      max-width: 473px;
      justify-content: center;
      align-items: center;

      .button {
        margin: 0 16px;
      }
    }
  }
}
