.accordion {
  margin: 12px 0px;

  details {
    @extend .p-xl-regular;
    color: $color-gray-01;
    width: 100%;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
  }

  details summary:before {
    height: 18px;
    width: 18px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5V9.5M9 9.5V14M9 9.5H13.5M9 9.5L4.5 9.5' stroke='%234F4F4F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: top;
    background-repeat: no-repeat;
    position: absolute;
    left: -8px;
    top: 4px;
  }

  details[open] summary:before {
    height: 18px;
    width: 18px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3337 8.5L2.66699 8.5' stroke='%23121212' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: top;
    background-repeat: no-repeat;
    position: absolute;
    left: -8px;
    top: 4px;
  }

  summary {
    @extend .p-xl-semi;
    color: $color-gray-06;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 18px;
  }

  summary::-webkit-details-marker {
    display: none;
  }
}
