.login-view {
  background-color: $color-blue;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .login-view__close-btn {
    z-index: 0;
    position: absolute;
    top: 45px;
    right: 48px;
  }

  .login-view__decoration {
    position: relative;
    z-index: 0;
  }

  .login-view__content {
    width: 90%;
    max-width: 537px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-bottom: 90px;

    .login-view__logo {
      margin-top: 100px;
      margin-bottom: 80px;
    }

    .login-view__title {
      @extend .headings-h1;
      margin-bottom: 38px;
      text-align: center;
    }

    .login-view__subtitle {
      @extend .p-xl-regular;
      margin-bottom: 38px;
    }

    .login-view__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .login-view__email-input {
        width: 100%;
        max-width: 350px;
        margin-bottom: 38px;
      }

      .login-view__password-input {
        width: 100%;
        max-width: 350px;
        margin-bottom: 10px;
      }

      .login-view__password-recovery {
        width: 100%;
        max-width: 350px;
        margin-bottom: 38px;
        z-index: 2;
      }

      .login-view__password-recovery a {
        color: $color-gray-02;
        font-weight: 700;
        cursor: pointer;
      }

      .login-view__social-title {
        @extend .p-xl-regular;
        margin-bottom: 38px;
      }

      .login-view__social-actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 0;
        flex-wrap: wrap;

        button {
          margin: 8px 4px;
        }
      }
    }
  }

  .login-view__button-login {
    @extend .reset-button-style;

    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-bottom: 38px;
  }

  .login-view__button-login-enabled {
    background: $color-gray-01;
    cursor: pointer;
    z-index: 2;
  }

  .login-view__button-login-disabled {
    background: rgba($color-gray-01, 0.25);
  }

  // Responsive

  @media (max-height: 768px) {
    .login-view__content {
      padding-bottom: 20px;

      .login-view__logo {
        margin-top: 50px;
        margin-bottom: 40px;
      }

      .login-view__subtitle {
        margin-bottom: 16px;
      }

      .login-view__form .login-view__social-title {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    .login-view__close-btn {
      top: 16px;
      right: 16px;
    }

    .login-view__content {
      .login-view__logo {
        margin-top: 100px;
      }
    }
  }
}

.login-view--app {
  background-color: #ffffff;

  .login-view__close-btn {
    display: none;
  }

  .login-view__content {
    .login-view__logo {
      margin-top: 40px;
      margin-bottom: 80px;
    }
    .login-view__title {
      @extend .headings-h4;
      width: 315px;
      margin-bottom: 12px;
    }

    .login-view__subtitle {
      @extend .p-xl-regular;
    }
    .login-view__form {
      .login-view__social-title {
        display: none;
      }

      .login-view__social-actions {
        display: none;
      }
    }
  }
}
