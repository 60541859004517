$transition-speed: 0.8s;
$transition-anim: ease-in;
$step1-color: $color-pink;
$step2-color: $color-blue;
$step3-color: $color-green;

.sign-up-steps {
  display: flex;
  justify-content: center;
  align-items: center;

  .sign-up-steps__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 48px;
    height: 48px;

    background-color: #1d1d1b;
    border: 1px solid #1d1d1b;
    box-sizing: border-box;
    border-radius: 50%;

    @extend .p-xl-bold;

    &.sign-up-steps__button--step1 {
      color: $step1-color;
      transition: all $transition-anim $transition-speed;
    }

    &.sign-up-steps__button--step2 {
      color: $step2-color;
      transition: all $transition-anim $transition-speed;
    }

    &.sign-up-steps__button--step3 {
      color: $step3-color;
      transition: all $transition-anim $transition-speed;
    }

    &.sign-up-steps__button--active {
      color: #1d1d1b;
      background-color: transparent;
      transition: all $transition-anim $transition-speed;
    }
  }

  .sign-up-steps__separator {
    background-color: #000000;
    height: 1px;
    flex-grow: 1;
  }
}
