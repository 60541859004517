.two-levels-selector {
  margin-bottom: 12px;
  text-align: left;

  .select__control {
    @extend .p-xl-regular;
    min-height: 50px;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid $color-gray-08;
    box-sizing: border-box;

    .select__value-container {
      padding: 2px 16px;
    }

    .select__placeholder {
      color: $color-gray-08;
    }
  }

  .group-heading-wrapper {
    display: flex;

    .group-check {
      width: 16px;
      height: 16px;
      background: $color-gray-01;
      border: 1px solid $color-gray-01;
      border-radius: 2px;
    }
  }

  .select__option {
    font-size: 14px;
    line-height: 23px;
    color: $color-gray-06;
  }

  .select__option--is-focused {
    background: $color-gray-07;
    font-weight: 600;
  }

  .select__option--is-selected {
    background: $color-gray-09;
  }
}