.input-license {
  width: 100%;
  height: 50px;
  display: flex;

  .input-license__input {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 24px 0px 0px 24px;
    height: 50px;
    width: 100%;
    padding: 0 16px;

    /* Paragraph XL/XL Regular */

    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #4f4f4f;

    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }
}

.input-license__button {
  @extend .reset-button-style;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: $color-gray-01;
  border-radius: 0px 62px 62px 0px;
  padding: 0 24px;
  border: 0;

  /* Paragraph XL/XL Bold */

  font-family: Titillium Web;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  color: #FFFFFF;
}
