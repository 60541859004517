.demo-popup {
  background-color: #8cd2eb;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .demo-popup__logo {
    position: absolute;
    top: 45px;
    left: 48px;
  }

  .demo-popup__close-btn {
    position: absolute;
    top: 45px;
    right: 48px;
  }

  .demo-popup__content {
    width: 908px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .demo-popup__title {
      @extend .headings-h1;
      margin-bottom: 24px;
    }

    .demo-popup__subtitle {
      @extend .p-xl-regular;
      margin-bottom: 64px;
      max-width: 567px;

      a {
        font-weight: 700;
        color: $color-gray-01;
        text-decoration: none;
      }
    }

    .demo-popup__packages-box {
      background: #ffffff;
      border-radius: 8px;
      padding: 8px 12px;
      width: 908px;
      max-height: 50vh;
      overflow-y: auto;
    }

    .demo-popup__actions {
      margin-top: 32px;
      display: flex;
      width: 100%;
      max-width: 473px;
      justify-content: center;
      align-items: center;

      .button {
        margin: 0 16px;
      }
    }
  }
}
