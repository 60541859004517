.recover-view {
  background-color: $color-pink;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .recover-view__close-btn {
    z-index: 0;
    position: absolute;
    top: 45px;
    right: 48px;
  }

  .recover-view__decoration {
    position: relative;
    z-index: 0;
  }

  .recover-view__content {
    width: 90%;
    max-width: 537px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    .recover-view__logo {
      margin-top: 100px;
      margin-bottom: 80px;
    }

    .recover-view__title {
      @extend .headings-h1;
      margin-bottom: 38px;
      text-align: center;
    }
    .recover-view__subtitle {
      @extend .p-xl-regular;
      margin-bottom: 38px;
    }

    .recover-view__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .recover-view__password-input {
        width: 100%;
        max-width: 350px;
        margin-bottom: 38px;
      }
    }
  }

  .recover-view__button-recover {
    @extend .reset-button-style;

    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-bottom: 38px;
  }
  .recover-view__button-recover-enabled {
    background: $color-gray-01;
    cursor: pointer;
    z-index: 2;
  }
  .recover-view__button-recover-disabled {
    background: rgba($color-gray-01, 0.25);
  }
}
