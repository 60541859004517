.animated-logo {
  width: 304px;
  height: 184px;
  // background-color: burlywood;
  margin-bottom: 24px;
  margin-top: -27px;

  .step-card-wrapper {
    position: relative;
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .step-card {
    position: absolute;
    top: 0;

    opacity: 0;
    // border: 2px solid red;
    &.step-card--show {
      opacity: 1;
    }
  }

  .dot-steps {
    z-index: 109;
    border: 2px solid red;
    background-color: burlywood;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
  }
}
