//TODO: variables

.input-mail-button {
  position: relative;
  width: calc(100% - 24px);

  height: 50px;
  background: #ffffff;

  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 16px;

  .input-mail-button__input {
    height: 50px;
    background: transparent;
    border: none;
    opacity: 0;
    width: 100%;
    transition: all ease-in 0.1s;

    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: #4f4f4f;

    &:focus,
    &:active {
      outline: none;
    }

    &.input-mail-button__input--active {
      outline: none;
      height: 34px;
      margin-top: 14px;
      opacity: 1;
      transition: all ease-in 0.1s;

      ~.input-mail-button__label {
        height: 23px;
        top: 0;
        left: 18px;
        transition: all ease-in 0.1s;
        /* Paragraph M/M Regular */

        font-family: Titillium Web;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
      }
    }
  }

  .input-mail-button__label {
    position: absolute;
    height: 50px;
    top: 0;
    left: 16px;
    transition: all ease-in 0.1s;

    /* Paragraph XL/XL Regular */

    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    /* or 167% */

    display: flex;
    align-items: center;

    color: #1d1d1b;
  }
}


.input-mail-button__input:focus,
.input-mail-button__input:active {
  outline: none;
  height: 34px;
  margin-top: 14px;
  opacity: 1;
  transition: all ease-in 0.1s;

  ~.input-mail-button__label {
    height: 23px;
    top: 0;
    left: 18px;
    transition: all ease-in 0.1s;
    /* Paragraph M/M Regular */

    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
  }
}

.input-mail-button__no-button {
  width: 100%;
}

.input-mail-button__button {
  position: absolute;
  top: -1px;
  right: -24px;
  @extend .reset-button-style;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: $color-gray-01;
  border-radius: 50%;
}
