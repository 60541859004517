.home-view__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 350px;
  height: 408px;
  margin-bottom: 24px;
  position: relative;
  margin-right: 1%;

  .home-view__card-tag {
    background: #121212;
    padding: 2px 12px;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 2px;
    color: #ffffff;
    position: absolute;
    top: -12px;
    text-transform: uppercase;
  }

  .home-view__card-header {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Titillium Web;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 31px;
    color: #ffffff;
    text-align: center;

  }


  .home-view__card-header-subtitle {
    display: flex;
    margin-top: 6px;
    height: 16px;
    width: auto;
    img {
      height:100%;
      width: auto;
    }
  }

  .home-view__card-content {
    flex-grow: 1;
    padding: 24px;
    width: 100%;

    justify-content: center;
    align-items: flex-start;

    @extend .p-l-regular;
    font-size: 16px;
    color: $color-gray-06;
  }

  .home-view__card-content-tag {
    margin: 0px auto 10px auto;
    background: #ffffff;
    padding: 2px 10px;
    text-transform: uppercase;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    color: #121212;
    width: auto;
  }

  .home-view__card-footer {
    display: flex;
    padding-bottom: 24px;

    .button {
      margin: 0 12px;
    }
  }

  &.home-view__card--yellow {
    background: $color-yellow;
  }

  &.home-view__card--brown {
    background: $color-brown;

    .home-view__card-content {
      color: #ffffff;
    }
  }

  &.home-view__card--orange {
    background: $gradient-orange;

    .home-view__card-content {
      color: #ffffff;
    }
  }

  &.home-view__card--red-bg {
    background: url('../../../assets/img/bg_edelvives.png'), linear-gradient(148.48deg, #ff531d 18.3%, #b4131c 98.15%);
    background-repeat: no-repeat !important;
    background-size: 180% !important;
    background-position: bottom !important;

    .home-view__card-content {
      color: #ffffff;
    }
  }

  &.home-view__card--red {
    background-color: $color-red-byme;
    height: auto;

    .home-view__card-content {
      color: #ffffff;
    }
  }

  &.home-view__card--blue {
    background: $color-blue;

    .home-view__card-header {
      color: #000000;
      width: 60px;
      @extend .headings-h1;
      text-align: center;
      font-size: 31px;
    }

    .home-view__card-content {
      color: #000000;
    }
  }



  &.home-view__card--light-orange {
    background: $color-light-orange;
    .home-view__card-header {
      color: #000000;
      width: 60px;
      @extend .headings-h1;
      font-size: 31px;
      text-align: center;

    }

    .home-view__card-content {
      color: #000000;
    }
  }

  &.home-view__card--demo {
    background: $color-blue;
    width: 65%;
    height: 408px;
    position: relative;
    padding: 0;

    .home-view__card-header {
      @extend .headings-h2;
      color: $color-gray-01;
      padding: 24px;
      height: auto;
      margin-top: 42px;
      margin-left: 20%;
    }

    .home-view__card-content {
      @extend .p-xl-regular;
      color: $color-gray-01;
      padding: 24px 24px;
      width: 70%;
      margin-left: 25%;
    }

    .home-view__card-footer {
      background-color: $color-light-orange;
      width: 100%;
      height: 97px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0;

      .button {
        margin: 0 24px;
      }
    }

    .home-view__card-decoration {
      position: absolute;
      bottom: -6px;
      left: -20px;
    }
  }
}

// Responsive - max-height

@media (max-height: 768px) {
  .home-view__card {
    height: 340px;
    .home-view__card-content {
      padding-top: 0;
    }
  }

  .home-view__card.home-view__card--red {
    height: auto;
  }

  .home-view__card.home-view__card--demo {
    height: 380px;

    .home-view__card-content {
      width: 50%;
    }
  }
}

// Responsive - max-width

@media (max-width: 1220px) {
  .home-view__card,
  .home-view__card.home-view__card--red-bg {
    width: 48%;
    margin-right: 2%;
    // height: 380px;

    .home-view__card-content {
      padding-top: 0;
    }
  }

  // .home-view__card.home-view__card--red,
  .home-view__card.home-view__card--demo {
    height: 100%;
  }
  .home-view__card.home-view__card--demo {
    width: 48%;
    height: 408px;
    .home-view__card-header,
    .home-view__card-content {
      width: 100%;
      margin-left: 0px;
      padding-bottom: 20px;
    }

    .home-view__card-decoration {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .home-view__card,
  .home-view__card.home-view__card--red-bg {
    width: 100%;
    height: auto;
  }

  .home-view__card.home-view__card--blue {
    height: auto;
  }

  .home-view__card.home-view__card--light-orange {
    height: auto;
  }

  .home-view__card.home-view__card--red {
    height: auto;

  }

  .home-view__card.home-view__card--demo {
    height: 380px;
    width: 100%;
    .home-view__card-header {
      width: 60%;
      margin-left: 35%;
      padding-top: 24px;
      margin-top: 24px;
    }
    .home-view__card-content {
      width: 60%;
      margin-left: 35%;
    }
    .home-view__card-decoration {
      display: block;
      img {
        height: 300px;
        width: auto;
      }
    }
  }
}

@media (max-width: 645px) {
  .home-view__card.home-view__card--demo {
    width: 100%;
    .home-view__card-header,
    .home-view__card-content {
      width: 100%;
      margin-left: 0px;
      padding-bottom: 20px;
    }

    .home-view__card-decoration {
      display: none;
    }
  }
}
