.drawer {
  position: fixed;
  bottom: -21px;
  left: 0;
  z-index: 110;

  display: flex;
  padding: 40px 48px;
  height: 276px;
  width: 100%;
  background: #ffd296;
  padding-bottom: 70px;

  .drawer__close-btn {
    z-index: 0;
    position: absolute;
    top: 24px;
    right: 48px;
  }

  .drawer__content {
    width: 100%;
    max-width: 690px;
    text-align: left;
  }

  .drawer__title {
    @extend .headings-h1;
    color: $color-gray-01;
    margin: 12px 0;
  }

  .drawer__text {
    @extend .p-xl-regular;
    color: $color-gray-01;
  }

  .drawer__buttons {
    display: flex;
    padding-top: 0.5em;

    & > * {
      padding: 1em;
      margin-left: 1em;
      background-color: rgba(18, 18, 18, 0.05);
      border-radius: 8px;
      cursor: pointer;
    }

    & > *:first-child {
      margin-left: 0;
    }
  }

  &.drawer--info {
    padding-top: 55px;
  }

  &.drawer--alert {
    background: #fdefef;
  }
}
