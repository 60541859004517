.checkbox {
  @extend .p-l-regular;

  .checkbox__container {
    display: inline-block;
    position: relative;
    padding-left: 32px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkbox__label {
      display: flex;
      align-items: center;
      text-align: left;

      img {
        width: 3em;
        vertical-align: middle;
        padding-right: 1em;
      }

      .checkbox__label-tooltip-wrap {
        position: relative;
      }

      .checkbox__label-tooltip-wrap .checkbox__label-tooltip-text {
        visibility: hidden;
        width: 8em;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 0.4em 0.1em;
        position: absolute;
        z-index: 201;
        bottom: 150%;
        left: 50%;
        margin-left: -4em;
      }

      .checkbox__label-tooltip-wrap .checkbox__label-tooltip-text::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -0.8em;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }

      .checkbox__label-tooltip-wrap:hover .checkbox__label-tooltip-text {
        visibility: visible;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ .checkbox__checkmark {
      background-color: $color-gray-01;
      border: 1px solid $color-gray-01;
    }

    input:checked ~ .checkbox__checkmark:after {
      display: block;
    }

    .checkbox__checkmark {
      position: absolute;
      left: 0;
      height: 18px;
      width: 18px;
      background: #ffffff;
      border: 1px solid $color-gray-05;
      border-radius: 2px;
      top: 3px;
    }

    .checkbox__checkmark-vertical-middle {
      top: 50%;
      transform: translate(0, -50%);
    }

    .checkbox__checkmark:after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-radius: 1px;
    }
  }

  .checkbox__container:hover {
    input ~ .checkbox__checkmark {
      opacity: 0.8;
    }
  }
}
