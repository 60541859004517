.sign-up-view {
  .sign-up-view__logo {
    // height: 150px;
    // height: 184px;
  }

  .sign-up-view__steps {
    width: 350px;
    margin-bottom: 60px;
  }

  .sign-up-view__decoration {
    z-index: -1;
  }

  .sign-up-view__form {
    width: 537px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .sign-up-view__footer {
    display: flex;
    align-items: center;
    position: relative;
    z-index: -1;
    padding-top: 30px;

    .sign-up-footer-btn-dot {
      @extend .reset-button-style;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      background: $color-gray-01;
      opacity: 0.25;
      border-radius: 42px;
      margin-right: 24px;
    }

    .sign-up-footer-btn-next {
      @extend .reset-button-style;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background: $color-gray-01;
      border-radius: 50%;
    }
  }

  // Responsive

  @media (max-height: 768px) {
    .animated-background-layout .animated-background-layout__content {
      height: auto;
      padding: 0 30px 16px 30px;
    }

    .sign-up-view__steps {
      margin-bottom: 30px;
    }

    .sign-up-form-password__subtitle {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;

    .sign-up-view__steps {
      display: none;
    }

    .sign-up-view__form {
      width: 95%;
    }

    .sign-up-view__decoration {
      display: none;
    }

    .sign-up-form-course__subtitle,
    .sign-up-form-password__subtitle {
      max-width: 85vw !important;
    }
    .animated-background-layout {
      width: 100% !important;
    }
    .animated-background-layout .animated-background-layout__content {
      min-height: auto;
      height: auto;
      // padding: 0 30px 16px 30px;
    }
    .animated-background-layout
      .animated-background-layout__background--step-1
      .animated-background-layout__background--layer1 {
      width: 100%;
      height: 110%;
    }

    .animated-background-layout
      .animated-background-layout__background--step-2
      .animated-background-layout__background--layer2 {
      width: 100%;
      height: 110%;
    }
    .animated-background-layout
      .animated-background-layout__background--step-3
      .animated-background-layout__background--layer3 {
      width: 100%;
      height: 110%;
    }
  }
}
