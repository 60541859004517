.home-view {
  .home-view__card-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1098px;
    margin: 0 auto;
  }

  .home-view__card-list__mx {
    justify-content:flex-start !important;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 1098px;
    margin: 0 auto;

  }
}
