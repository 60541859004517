.sign-up-form-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sign-up-form-profile__title {
    @extend .headings-h1;
    margin-bottom: 12px;
  }

  .sign-up-form-profile__subtitle {
    @extend .p-xl-regular;
    margin-bottom: 38px;
  }

  .sign-up-form-profile__form {
    width: 537px;

    .sign-up-form-profile__input-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .sign-up-form-profile__input {
      margin-bottom: 16px;
      flex-grow: 1;

      &:first-child {
        margin-right: 24px;
      }
    }

    .sign-up-form-profile__school-title {
      @extend .p-xl-regular;
      color: #000000;
      text-align: left;
      width: 100%;
      margin-bottom: 16px;
    }

    .sign-up-form-profile__school-search {
      .school-async-select {
        text-align: left;

        .select__control {
          @extend .p-xl-regular;
          min-height: 50px;
          border-radius: 8px;
          box-shadow: none;
          border: none;

          .select__value-container {
            padding: 2px 16px;
          }

          .select__placeholder {
            color: $color-gray-02;
          }

          .select__indicator-separator {
            display: none;
          }
        }

        .select__menu-list {
          max-height: 200px;
          z-index: 109;
        }

        .select__option {
          font-size: 14px;
          line-height: 23px;
          color: #4f4f4f;
        }

        .select__option--is-focused {
          background: #f2f2f2;
          font-weight: 600;
        }

        .option-text {
          .option-left-text {
            text-align: left;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:active,
            &:hover {
              direction: rtl;
              padding-right: 1em;
              &:after {
                content: '\200E‎';
              }
            }
          }
          .option-right-text {
            float: right;
            text-align: right;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sign-up-form-profile {
    .sign-up-form-profile__form {
      width: 85vw;
      .sign-up-form-profile__input-wrapper {
        flex-direction: column;
      }
      .sign-up-form-profile__input {
        &:first-child {
          margin-right: 0px;
        }
      }
    }
  }
}
