.help-form-sales {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .help-form-sales__form {
    width: 100%;

    .help-form-sales__label {
      @extend .p-xl-regular;
      color: #000000;
      margin-bottom: 8px;
    }

    .help-form-sales__element {
      margin-bottom: 16px;
    }

    .help-form-sales__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }
  }

  .two-levels-selector,
  .basic-select {
    text-align: left;

    .select__control {
      @extend .p-xl-regular;
      min-height: 50px;
      border-radius: 8px;
      box-shadow: none;
      border: 1px solid $color-gray-05;

      .select__value-container {
        padding: 2px 16px;
      }

      .select__placeholder {
        color: $color-gray-02;
      }
    }

    .select__option {
      font-size: 14px;
      line-height: 23px;
      color: #4F4F4F;
    }

    .select__option--is-focused {
      background: #F2F2F2;
      font-weight: 600;
    }

    .select__option--is-selected {
      background: #d3d3d3;
    }
  }

  textarea {
    @extend .p-xl-regular;
    min-height: 50px;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid $color-gray-05;
    padding: 16px;
    width: 100%;
    resize: none;

    &:focus-visible {
      outline: none;
    }
  }

  .input-file {
    height: 90px;
    background: #FFFFFF;
    border: 1px dashed #BDBDBD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 32px 16px;
    position: relative;
    cursor: pointer;

    .input-file__label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      span:first-child {
        display: inline-flex;
        align-items: center;
        margin-right: 4px;

        &:before {
          content: "";
          height: 15px;
          width: 16px;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.9998 14.6998C11.9763 14.6998 15.1998 11.4763 15.1998 7.4998C15.1998 3.52335 11.9763 0.299805 7.9998 0.299805C4.02335 0.299805 0.799805 3.52335 0.799805 7.4998C0.799805 11.4763 4.02335 14.6998 7.9998 14.6998ZM11.3362 6.86341L8.6362 4.16341C8.28473 3.81194 7.71488 3.81194 7.36341 4.16341L4.66341 6.86341C4.31194 7.21488 4.31194 7.78473 4.66341 8.1362C5.01488 8.48767 5.58473 8.48767 5.9362 8.1362L7.0998 6.9726L7.0998 10.1998C7.0998 10.6969 7.50275 11.0998 7.9998 11.0998C8.49686 11.0998 8.8998 10.6969 8.8998 10.1998V6.9726L10.0634 8.1362C10.4149 8.48767 10.9847 8.48767 11.3362 8.1362C11.6877 7.78473 11.6877 7.21488 11.3362 6.86341Z' fill='%23121212'/%3E%3C/svg%3E");
          margin-right: 10px;
        }
      }

      span:last-child {
        text-decoration: underline;
      }
    }

    .input-file__input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

}
