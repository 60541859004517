.landing-legal-view {

  .landing-legal-view__wrapper {
    max-width: 724px;
    width: 100%;

    z-index: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    .landing-legal-view__title {
      @extend .headings-h2;
      text-align: center;
      padding: 40px 40px 12px 40px;
    }

    .landing-legal-view__text {
      width: 100%;
    }
  }

}
