.loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  z-index: 200;
  cursor: wait;

  .loading__icon {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 6rem;
    height: 6rem;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: 100%;
      animation: loading__rotation 2s infinite linear;
    }
  }
}

@keyframes loading__rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
