.help-view {

  .help-view__wrapper {
    max-width: 724px;
    width: 100%;
    z-index: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    .help-view__title {
      @extend .headings-h2;
      text-align: center;
      padding: 40px 40px 12px 40px;
    }

    .help-view__subtitle {
      @extend .p-xl-regular;
      text-align: center;
      padding: 0;
      color: $color-gray-02;
      max-width: 580px;
    }

    .help-view__faq {
      margin-top: 24px;
      width: 100%;
    }


    .help-view__contact {
      width: 100%;
    }
  }
}
