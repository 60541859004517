.sign-up-form-course {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sign-up-form-course__title {
    @extend .headings-h1;
    margin-bottom: 12px;
  }

  .sign-up-form-course__subtitle {
    @extend .p-xl-regular;
    margin-bottom: 38px;
  }

  .sign-up-form-course__form {
    width: 350px;

    .sign-up-form-course__select {
      margin-bottom: 16px;
      flex-grow: 1;
    }
  }

  .two-levels-selector {
    text-align: left;

    .select__control {
      @extend .p-xl-regular;
      min-height: 50px;
      border-radius: 8px;
      box-shadow: none;
      border: none;

      .select__value-container {
        padding: 2px 16px;
      }

      .select__placeholder {
        color: $color-gray-02;
      }
    }

    .group-heading-wrapper {
      display: flex;

      .group-check {
        width: 16px;
        height: 16px;
        background: $color-gray-01;
        border: 1px solid $color-gray-01;
        border-radius: 2px;
      }
    }

    .select__option {
      font-size: 14px;
      line-height: 23px;
      color: #4F4F4F;
    }

    .select__option--is-focused {
      background: #F2F2F2;
      font-weight: 600;
    }

    .select__option--is-selected {
      background: #d3d3d3;
    }
  }

  .basic-multi-select {
    text-align: left;

    .select__control {
      @extend .p-xl-regular;
      min-height: 50px;
      border-radius: 8px;
      box-shadow: none;
      border: none;

      .select__value-container {
        padding: 2px 16px;
      }

      .select__placeholder {
        color: $color-gray-02;
      }
    }

    .select__option {
      font-size: 14px;
      line-height: 23px;
      color: #4F4F4F;
    }

    .select__option--is-focused {
      background: #F2F2F2;
      font-weight: 600;
    }

    .select__option--is-selected {
      background: #d3d3d3;
    }
  }
}
