// Useful styles and mixins

// Use with @extend .reset-button-style

.reset-button-style {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: opacity 150ms ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}
