.social-button {
  @extend .reset-button-style;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-height: 40px;
  height: 40px;
  min-width: 260px;
  width: 350px;
  background: #ffffff;
  border-radius: 4px;

  &.social-button--linked {
    border: 1px solid $color-gray-06;
    background: #f9f9f9;

    .social-button__label {
      color: $color-gray-06;
    }
  }

  &:disabled {
    background: $color-gray-07;
    cursor: not-allowed;

    .social-button__label {
      color: $color-gray-08;
    }
  }

  .social-button__image {
    width: 24px;

    svg {
      width: 100%;
    }
  }

  .social-button__label {
    @extend .label-social-btn;

    color: $color-btn-gray;
    flex-grow: 1;
    padding-right: 24px;
  }
}
