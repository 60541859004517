.background-decoration {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: -1;

  .background-decoration__image {
    position: absolute;

    &.background-decoration__image--paloma {
      top: 0;
      left: 0;
    }

    &.background-decoration__image--palomitas {
      bottom: 0;
      right: 0;
    }

    &.background-decoration__image--chica {
      top: 42px;
      left: 35px;
    }

    &.background-decoration__image--monstruo {
      bottom: 30px;
      right: 36px;
    }
  }
}
