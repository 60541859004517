.home-layout {
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .home-layout__header {
    width: 100%;
  }

  .home-layout__content {
    width: 100%;
    padding: 40px 48px 0 48px;
    flex-grow: 1;
  }

  .home-layout__footer {
    width: 100%;
  }
}
