.email-sent-view {
  background-color: #d4e696;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .email-sent-view__close-btn {
    z-index: 0;
    position: absolute;
    top: 45px;
    right: 48px;
  }

  .email-sent-view__decoration {
    position: relative;
    z-index: 0;
  }

  .email-sent-view__content {
    width: 90%;
    max-width: 445px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
    text-align: left;

    .email-sent-view__check {
      width: 100%;
      margin-bottom: 24px;
    }

    .email-sent-view__title {
      @extend .headings-h1;
      font-weight: normal;
      margin-bottom: 38px;
    }
    .email-sent-view__subtitle {
      @extend .p-xl-regular;
      a {
        font-weight: 700;
        color: $color-gray-01;
        text-decoration: none;
      }
    }
  }
}
