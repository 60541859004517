    .soon-card {
      width: 206px;
      height: 156px;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      border-radius: 8px;
      margin: 8px
    }

    .soon-card__img {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .soon-card__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      padding: 0 16px 12px 16px
    }
